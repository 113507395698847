const constraints = {
  name: {
    msg: "Как к Вам обращаться?",
    messageError: "Введите имя",
    pattern: "^[а-яА-ЯёЁa-zA-Z_\\ ]{2,60}$",
  },

  phone: {
    // msg: "Недопустимый номер телефона!",
    msg: "Введите корректный номер телефона",
    messageError: "Введите корректный номер телефона",
    pattern: "^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$",
  },

  email: {
    // msg: "Недопустимый email!",
    msg: "Поле не должно быть пустым и должно содержать корректный e-mail",
    messageError: "Поле не должно быть пустым и должно содержать корректный e-mail",
    pattern:
      "^[\\w-\\.\\d*]+@[\\w\\d]+(\\.\\w{2,4})$",
    // pattern:
    //   "[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$",
  },

  password: {
    msg: "Поле не должно быть пустым",
    messageError: "Поле не должно быть пустым",
    pattern: "^[а-яА-ЯёЁa-zA-Z_\\ ]{2,60}$",
    // pattern:
    //   "[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$",
  },


  subject: {
    msg: "Не указана тематика!",
  },

  link: {
    msg: "Укажите ссылку на профиль!",
  },

  firstName: {
    msg: "Как к Вам обращаться?",
  },

  lastName: {
    msg: "Как к Вам обращаться?",
  },

  coupon: {
    msg: "Введите промокод!",
  },

  file: {
    msg: "Загрузите файл!",
  },
};

export default constraints;
